exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html, body {\n    height: 100%;\n    width: 100%;\n    margin: 0;\n}\n\npre {\n    font-family: Consolas, \"Courier New\", Courier, monospace;\n}\n\nmain {\n    overflow-x: hidden;\n    position: fixed;\n    height: 100%;\n    width: 100%;\n}\n\n._3ntjvAOzd3T-mege8LAnP0 {\n    position: absolute;\n    top: 24px;\n    bottom: 88px;\n    padding: 4px 16px;\n    overflow-y: auto;\n    word-spacing: 0px;\n}\n\n._3f1hFnFR1bNbd0SOipFtL7 {\n    position: absolute;\n    top: 24px;\n    bottom: 88px;\n    padding: 0 16px;\n    overflow-y: auto;\n    word-spacing: 0px;\n}\n\n@media (min-width: 768px) {\n    ._3TR-zgD-eEPFgs0StGw81G {\n        display: none;\n    }\n\n    ._3ntjvAOzd3T-mege8LAnP0 {\n        left: 24px;\n        width: calc(50% - 38px);\n    }\n\n    ._3f1hFnFR1bNbd0SOipFtL7 {\n        right: 24px;\n        width: calc(50% - 38px);\n    }\n\n    .paq_l2gDoVrFFXVS9knID._1VmnQ4sDIUGae7MUWUXJWr {\n        display: none !important;\n    }\n}\n\n@media (max-width: 768px) {\n    ._3TR-zgD-eEPFgs0StGw81G {\n        font-size: 12px;\n    }\n    \n    .wP8DNmCXOQ3DOCD581Qbv {\n        display: none;\n    }\n\n    .lfEzW-QkJuIZ6SnG6kM-b {\n        line-height: 20px;\n        padding-top: 12px;\n    }\n\n    ._3ntjvAOzd3T-mege8LAnP0 {\n        left: 24px;\n        width: calc(100% - 80px);\n    }\n\n    ._3f1hFnFR1bNbd0SOipFtL7 {\n        right: calc(-100% + 108px);\n        width: calc(100% - 80px);\n    }\n\n    ._3ntjvAOzd3T-mege8LAnP0._30qyV2UgXPBnrBMToOAm9d {\n        left: calc(-100% + 108px);\n        width: calc(100% - 80px);\n    }\n\n    ._3f1hFnFR1bNbd0SOipFtL7._30qyV2UgXPBnrBMToOAm9d {\n        right: 24px;\n        width: calc(100% - 80px);\n    }\n\n    .paq_l2gDoVrFFXVS9knID._3Dw8AkkAxTQBB83XGYAOMd {\n        display: none !important;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"main-left": "_3ntjvAOzd3T-mege8LAnP0",
	"mainLeft": "_3ntjvAOzd3T-mege8LAnP0",
	"main-right": "_3f1hFnFR1bNbd0SOipFtL7",
	"mainRight": "_3f1hFnFR1bNbd0SOipFtL7",
	"title-suffix-small": "_3TR-zgD-eEPFgs0StGw81G",
	"titleSuffixSmall": "_3TR-zgD-eEPFgs0StGw81G",
	"header-copy-output": "paq_l2gDoVrFFXVS9knID",
	"headerCopyOutput": "paq_l2gDoVrFFXVS9knID",
	"show-in-menu": "_1VmnQ4sDIUGae7MUWUXJWr",
	"showInMenu": "_1VmnQ4sDIUGae7MUWUXJWr",
	"title-suffix-big": "wP8DNmCXOQ3DOCD581Qbv",
	"titleSuffixBig": "wP8DNmCXOQ3DOCD581Qbv",
	"title": "lfEzW-QkJuIZ6SnG6kM-b",
	"show-output": "_30qyV2UgXPBnrBMToOAm9d",
	"showOutput": "_30qyV2UgXPBnrBMToOAm9d",
	"show-in-app-bar": "_3Dw8AkkAxTQBB83XGYAOMd",
	"showInAppBar": "_3Dw8AkkAxTQBB83XGYAOMd"
};